.addIcon {
  font-size: 50px;
  display: flex;
  justify-content: end;
  margin-right: 50px;
  cursor: pointer;
  margin: 10px;
}

.ViewPostWrapper {
  padding: 10px;
  background-color: #ffffff;
}
.shortinfoWrapper {
  margin-bottom: 20px;
  margin-left: 10px;
}

.imgThumb {
  width: 20rem;
}

.subCategoryWrapper {
  background-color: #ffffff;
  margin: 10px;
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .imgThumb {
    width: 100%;
  }
}
