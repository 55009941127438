.boxWrapper {
  width: 70%;
  background: white;
  padding: 20px;
  margin-top: 3%;
}

.imgRound {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.commentUserimage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.commentswrapper {
  display: flex;
  gap: 10px;
}
.commentind {
  padding: 10px;
  background-color: aliceblue;
}
.boxReportWrapper {
  background: white;
  padding: 20px;
  margin-top: 3%;
}

.dataTitle {
  font-size: 2rem;
  font-weight: 700;
}

.userpostinfo {
  font-size: 1.2rem;
}

.imgorvideodisplay {
  width: 60%;
}

@media only screen and (max-width: 600px) {
  .imgRound {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  .imgorvideodisplay {
    width: 90%;
  }

  .dataTitle {
    font-size: 1rem;
    font-weight: 500;
  }

  .userpostinfo {
    font-size: 0.8rem;
  }

}


@media only screen and (max-width: 768px) {

  .boxWrapper{
    width: 100%;
  }
}