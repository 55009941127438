.tableHeader {
  margin-top: 10%;
}

.tableBoxWrapper {
  padding: 10px;
  background: #9a32b1;
  color: white;
  font-size: 20px;
}

.listtbody {
  vertical-align: unset !important;
}
.pageNumber {
  font-size: 20px;
  margin: 0px 20px 0px 20px;
}

.pageNextPerviousBtnWrapper {
  border: 1px solid;
  display: flex;
  flex-direction: row;
}

.pageNextBtn {
  border-left: 1px solid;
  display: flex;
  flex-direction: row;
}

.pagePreviousBtn {
  border-right: 1px solid;
  display: flex;
  flex-direction: row;
}

.searchBoxWrapper {
  background: white;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.searchBox {
  border: none;
  border-bottom: 1px solid #58626f !important;
}
