.boxWrapper {
  width: 70%;
  background: white;
  padding: 20px;
  margin-top: 3%;
}

.imgRound {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.label {
  color: #8e9091;
}

.inputSetting {
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  padding: 5px 0px;
}

.inputSetting:focus {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
}

.inputSetting:focus-within {
  border: none;
  border-bottom: 1px solid orangered;
}

.buttonwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
}
