.notificationWrapper {
  background-color: white;
  width: 80%;
  padding: 10px;
}

.nameTextsWrapper {
  padding-top: 10px;
}

.textLable {
  color: #aaaaaa;
}
.nameTexts {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  padding-bottom: 10px;
  width: 100%;
}

.nameTexts:focus {
  border-bottom: 2px solid #f44336;
}
