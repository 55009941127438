.boxWrapper {
  width: 60%;
  background: white;
  padding: 10px;
  margin-top: 3%;
}

.imageWrapper {
  width: 38%;
  background: white;
  padding: 10px;
  margin-top: 3%;
}

.imgRound {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.commentUserimage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.commentswrapper {
  display: flex;
  gap: 10px;
}
.commentind {
  padding: 10px;
  background-color: aliceblue;
}

.imgThumb {
  width: 40rem;
}

@media only screen and (max-width: 768px) {

  .boxWrapper{
    width: 100%;
  }

  .imageWrapper {
    width: 100%
  }
}