html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.root-top-margin {
  margin-top: 10%;
}

.width-vh {
  width: 100vh;
}
.height-vh {
  height: 100vh;
}

.font-wt-100 {
  font-weight: 100;
}
.font-wt-200 {
  font-weight: 200;
}
.font-wt-300 {
  font-weight: 300;
}
.font-wt-400 {
  font-weight: 400;
}
.font-wt-500 {
  font-weight: 500;
}
.font-wt-600 {
  font-weight: 600;
}
.font-wt-700 {
  font-weight: 700;
}
.font-wt-800 {
  font-weight: 800;
}
.font-wt-900 {
  font-weight: 900;
}

.btn-primary {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  padding: 0.5rem 1rem;

  cursor: pointer;
}

.btn-primary:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.btn-disabled {
  color: #fff;
  background-color: #bbf29d;
  border-color: #bbf29d;
  padding: 0.5rem;
  cursor: pointer;
}

.w-100 {
  width: 100%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}

.w-400-p {
  width: 400px;
}

.p-none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.p-px-1 {
  padding: 2px;
}

.p-px-2 {
  padding: 4px;
}

.p-1 {
  padding: 0.5rem;
}

.p-2 {
  padding: 1rem;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pl-1 {
  padding-left: 0.5rem;
}

.pr-1 {
  padding-right: 0.5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pr-2 {
  padding-right: 1rem;
}

.pl-2 {
  padding-left: 1rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pr-3 {
  padding-right: 1.5rem;
}

.pl-3 {
  padding-left: 1.5rem;
}

.m-1 {
  margin: 0.5rem;
}

.m-2 {
  margin: 1rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 1rem;
}

.mr-1 {
  margin-right: 0.5rem;
}
.mr-2 {
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 0.5rem;
}
.ml-2 {
  margin-left: 1rem;
}

.text-align-center {
  text-align: center;
}

.display-b {
  display: block;
}

.display-n {
  display: none;
}

.display-flex {
  display: flex;
}

.flex-item-space-btw {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-item-center {
  display: flex;
  justify-content: center;
}

.flex-item-align-center {
  display: flex;
  align-items: center;
}

.bg-color-primary {
  background: #3fa215;
  color: white;
}

.color-danger {
  color: red;
  font-size: 12px;
}

.overflow-hidden {
  overflow: hidden;
}
.border-box-1 {
  border: 1px solid #3fa215;
}

.border-radius-1 {
  border-radius: 0.5rem;
}
.border-radius-2 {
  border-radius: 1rem;
}
.cursor-pointer {
  cursor: pointer;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.loader-spiner {
  width: 30px;
  height: 30px;
  border: 5px solid #fff;
  border-bottom-color: #1aa9da;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.width-100-overflow {
  width: 100%;
  overflow: scroll;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  overflow: hidden;
}
.loader::after {
  content: "";
  width: 96px;
  height: 4.8px;
  background: #fa9500;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: hitZak 1s linear infinite alternate;
}
.video-post-share {
  width: 100%;
}

.display-video {
  display: block;
}

.display-not-video {
  display: none;
}

@keyframes hitZak {
  0% {
    left: 0;
    transform: translateX(-1%);
  }
  100% {
    left: 100%;
    transform: translateX(-99%);
  }
}

@media only screen and (max-width: 768px) {
  .root-top-margin {
    margin-top: 10%;
  }
  .rasel-logo {
    width: 20%;
  }

  .app-logo-icon {
    width: 150px;
  }
}

@media (min-width: 768px) {
  .video-post-share {
    height: 500px; /* Set your desired height */
  }
  .rasel-logo {
    width: 15%;
  }
  .app-logo-icon {
    width: 200px;
  }
}
/* For desktops and larger devices */
@media (min-width: 1024px) {
  .video-post-share {
    height: 500px; /* Set a different height if needed */
  }
  .rasel-logo {
    width: 8%;
  }
  .app-logo-icon {
    width: 250px;
  }
}
