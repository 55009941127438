.dashboardFilterWrapper {
  padding: 20px;
  background-color: #ffff;
  margin: 10px 0px 10px 0px;
  display: flex;
}

.slectWrapper {
  padding: 0.4rem 0.375rem;
}

.dashboardResultWrapper {
  padding: 20px 0px 20px 0px;
  margin: 10px 0px 10px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.countWrapper {
  background-color: #ffff;
  padding: 10px;
  margin: 10px 10px 50px 10px;
  display: flex;
  justify-content: space-between;
  width: 31.6%;
  border-radius: 5px;
}
.iconBox {
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -25px;
  height: 80px;
}

.count {
  display: flex;
  justify-content: end;
}

.countValueText {
  color: #9b9696;
}



@media only screen and (max-width: 768px) {

  .countWrapper {
    width: 100%;
  }
}