.sidebar {
  height: 100vh !important;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 16.66666667%;
  display: block;
}

.logoNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
}

.navItem {
  padding: 10px;
}

.navLink {
  color: #000000 !important;
  display: flex !important;
  align-items: center !important;
  text-decoration: none !important;
}
.navLink:hover {
  color: var(--bs-nav-link-hover-color) !important;
}

.navIcons {
  margin-bottom: 2px;
}

.dropdownSelected {
  background-color: #e8e8e8;
}

.dropdown:hover {
  background-color: #e8e8e8;
}

.topMenu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.activeNavItem {
  background-color: #e8e8e8;
}

@media only screen and (max-width: 768px) {
  .sidebar {
    display: none;
    width: 80%;
    filter: drop-shadow(29px -2px 34px #000000);
  }
  .nav {
    display: none !important;
  }
}
