.boxWrapper {
  width: 70%;
  background: white;
  padding: 20px;
  margin-top: 3%;
}

.labelWrapper {
  color: gray;
}

.labelAnswer {
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid black;
}

.replayText {
  width: 100%;
  margin-top: 10px;
  resize: none;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}
