.headerWrapper {
  padding: 10px;
  background: #9a32b1;
  color: white;
  font-size: 20px;
}

.adWrapper {
  background-color: white;
  padding: 10px;
}

.nameTextsWrapper {
  padding-top: 10px;
}

.textLable {
  color: #aaaaaa;
}
.nameTexts {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  padding-bottom: 10px;
  width: 100%;
}

.nameTexts:focus {
  border-bottom: 2px solid #f44336;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.addIcon {
  font-size: 50px;
  display: flex;
  justify-content: end;
  margin-right: 50px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 10px;
}

.selectTexts {
  flex-grow: 1;
  padding: 10px;
  width: 100%;
}

.flexArea{
  display: flex;
  flex-wrap: wrap;
}