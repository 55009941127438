.form {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.field {
  flex: 1 1 calc(33.333% - 16px); /* Three items per row with gap accounted */
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.fieldFull {
  flex: 1 1 100%; /* Full-width for textarea and button */
  display: flex;
  flex-direction: column;
}

.field label,
.fieldFull label {
  margin-bottom: 8px;
}

.field input,
.field select,
.fieldFull textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.fieldFull textarea {
  resize: vertical;
  height: 100px;
}

.submitButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px;
  align-self: flex-start; /* Align the button to the left */
}

.submitButton:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 4px;
}

.successm {
  color: green;
  font-size: 0.875rem;
  margin-top: 4px;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.catImage {
  height: 100px;
}

.uploadImgButton {
  border: 1px solid #aaaaaa;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.uploadImgButton:hover {
  background-color: #aaaaaa;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
