.userWrapper {
  width: 70%;
  background: white;
  padding: 10px;
  margin-top: 3%;
}

.userNameBox {
  font-size: 20px;
}

.userProfileImg {
  width: 200px;
}

.userInfoBox {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.userinfoWrapper {
  width: 60%;
}


@media only screen and (max-width: 768px) {

  .userWrapper{
    width: 100%;
  }
}