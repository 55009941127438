.classifiedHeaderWrapper {
  padding: 10px;
  background: #9a32b1;
  color: white;
  font-size: 20px;
}

.addCategoryWrapper {
  background-color: white;
  padding: 10px;
}
.nameTextsWrapper {
  padding-top: 10px;
}

.textLable {
  color: #aaaaaa;
}
.nameTexts {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  padding-bottom: 10px;
  width: 100%;
}

.nameTexts:focus {
  border-bottom: 2px solid #f44336;
}

.imageWrapper {
  display: flex;
  justify-content: center;
}
.catImage {
  /* width: 100px; */
  height: 100px;
}

.uploadImgButton {
  border: 1px solid #aaaaaa;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.uploadImgButton:hover {
  background-color: #aaaaaa;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.selectFullWidth {
  flex-grow: 1;
  padding: 10px;
  width: 100%;
}

.addIcon {
  font-size: 50px;
  display: flex;
  justify-content: end;
  margin-right: 50px;
  cursor: pointer;
  margin: 10px;
}

.listButtons {
  padding: 5px;
  background-color: lightgreen;
}
