.loginBoxWrapper {
  flex-direction: column;
  height: 70vh;
}

.imagLogo {
  height: 80px;
  width: 80px;
}

.loginwrapper {
  width: 25%;
  padding: 20px;
  background-color: white;
  -webkit-box-shadow: 3px -1px 19px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 3px -1px 19px 0px rgba(0, 0, 0, 0.55);
  box-shadow: 3px -1px 19px 0px rgba(0, 0, 0, 0.55);
}
.loginwrapperloader {
  width: 25%;
}

.credentials {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
}
.credentials:focus {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
}

.credentials:focus-visible {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
}

.credentials:focus-within {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
}

.loginbtn {
  background-color: #e35a10;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  color: white;
  border: none;
}

.loginbtn:hover {
  -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.55);
  border: none;
}


@media only screen and (max-width: 768px) {

  .loginwrapper {
    width: 90%;
    padding: 10px;
    background-color: white;
    -webkit-box-shadow: 3px -1px 19px 0px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 3px -1px 19px 0px rgba(0, 0, 0, 0.55);
    box-shadow: 3px -1px 19px 0px rgba(0, 0, 0, 0.55);
  }
}